<!--
    @name: TaskTemplate
    @description：TaskTemplate
    @author: ZengWei
    @date: 2022-06-23 12:04
-->
<template>
  <div class="wrapper">
    <div class="wrap-left">
      <div class="head">
        <i class="iconfont iconguanli"></i>
        <span>任务引擎模板</span>
        <span v-if="!disabled" style="position: absolute;top: 0;right: 15px">
          <i class="iconfont iconxinzeng1" @click="openForm"></i>
        </span>
      </div>
      <div class="task-engine-list" v-loading="loading">
        <div v-if="taskTemplate.length>0" class="list-wrapper">
          <div
            class="list-item"
            v-for="item in taskTemplate"
            :key="item.id"
            :class="{active: taskGraphId === item.id}"
            @click="activeItem(item.id)">
            <div class="title">
              <i class="iconfont iconyingyong"></i>
              <span class="title-name">{{ item.name }}</span>
            </div>
            <div class="action" v-if="!disabled">
              <el-dropdown @command="handleCommand($event,item)">
                <span class="el-dropdown-link">
                  <i class="iconfont iconbimgis_gengduo"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="del">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无任务引擎数据"></el-empty>
      </div>
    </div>
    <div class="wrap-right" v-loading="!refresh">
      <TaskDesigner v-if="refresh" :task-id="taskGraphId"></TaskDesigner>
    </div>
  </div>
</template>

<script>
import {Empty} from "element-ui";
import TaskDesigner from "@/custom-component/task-flow/TaskDesigner.vue";
import {dataInterface} from "@/apis/data";

export default {
  name: "TaskTemplate",
  components: {
    TaskDesigner,
    'el-empty': Empty
  },
  props: ['taskEngineId','disabled'],
  data() {
    return {
      taskTemplate:[],
      taskGraphId: this.taskEngineId,
      loading: true,
      refresh: true
    }
  },
  created() {
    this.initList()
  },
  mounted() {
    if(this.taskEngineId){
      this.activeItem(this.taskEngineId)
    }
  },
  methods: {
    openForm(){
      this.$message.info('请在右侧设计模板')
      this.taskGraphId = 0
      this.refresh = false
      this.$nextTick(()=>{
        this.refresh = true
      })
    },
    initList(){
      const param = {
        __method_name__: 'dataList',
        object_uuid: 'object628619c716579',
        view_uuid: 'view628635336d974',
        transcode: 0,
        size: 1000,
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          this.taskTemplate = res.data.data.data
          this.loading = false
        }
      })
    },
    activeItem(id){
      this.refresh = false
      this.taskGraphId = id
      this.$emit('checked',id)
      this.$nextTick(()=>{
        this.refresh = true
      })
    },
    handleCommand(flag,row){
      if(flag === 'del'){
        this.del(row)
      }
    },
    del(row){
      this.$confirm('此操作删除此任务引擎, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object628619c716579',
          data_id: row.id,
          transcode: 0
        }
        dataInterface(params).then((res) => {
          if(res.data.code === 200){
            this.initList()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.active{
  background-color: #f0f7ff;
}
.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  border: 1px solid #eeeeee;

  .wrap-left{
    width: 280px;
    border-right: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;

    .head{
      position: relative;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      border-bottom: 1px solid #f2f4f5;

      span{
        padding-left: 10px;
      }
    }

    .task-engine-list{
      flex: 1;
      overflow-y: auto;
      .list-wrapper{
        .list-item{
          display: flex;
          justify-content: space-between;
          padding: 12px 16px;

          .title{
            width: 70%;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            .title-name{
              padding-left: 10px;
            }
          }

          &:hover{
            background-color: #f0f7ff;
          }
        }

      }
    }
  }
  .wrap-right{
    flex: 1
  }
}
</style>
